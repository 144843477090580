<template>
  <div class="swiper-container banner">
    <div class="swiper-wrapper">
        <div class="swiper-slide" v-for="item of swiperdata" :key="item" @click="toUrl()">
          <img :src="item.img">
        </div>
    </div>
    <div class="swiper-pagination"></div>
  </div>
  <div class="flexbox container">
      <div class="left">
        <div class="flexbox searchcontainer">
          <div class="flexbox subtitle">
              <i class="iconfont icon-search"></i>
              证书查询
          </div>
          <div class="flexbox content">
              <div class="flexbox inputcontainer">
                  <div class="inputtitle">证书编号：</div>
                  <input class="input" type="text" v-model="searchContent" placeholder="请输入证书编号" @keydown.enter="search">
              </div>
              <div class="flexbox tip">温馨提示：若您未查询到结果，可能数据正在上传，请过两天再次查询。给您带来不便，敬请见谅！</div>
              <div class="flexbox tip" style="margin-top: 0.1rem">证书查询时需要根据证书类型及样品编号进行查询:</div>
              <div class="flexbox tip" style="margin-top: 0.1rem">如查询小证信息则需输入样品编号“+2”查询证书；</div>
              <div class="flexbox tip" style="margin-top: 0.1rem">如查询大证信息则需输入样品编号“+1”查询证书；</div>
              <div class="flexbox tip" style="margin-top: 0.1rem">例：样品编号为YP2021000064且为小证，则输入642可进行查询。</div>
              <div class="flexbox buttoncontainer">
                  <div class="flexbox button search" @click="search">查询</div>
                  <div class="flexbox button reset" @click="reset">重置</div>
              </div>
          </div>
        </div>
      </div>
      <div class="right">
        <div class="flexbox msgcontainer">
          <div class="flexbox subtitle">
              <i class="iconfont icon-renwu"></i>
              最新消息
          </div>
          <div class="flexbox content">
              <div class="flexbox newsitem" v-for="item of newsList" :key="item" @click="topath(item.url)">
                <div class="title">{{item.title}}</div>
                <div class="time">{{item.time}}</div>
              </div>
          </div>
        </div>
      </div>
  </div>
</template>

<script>
// import Swiper from 'swiper';
export default {
  data(){
    return {
      searchContent: '',
      swiper: null,
    }
  },
  computed: {
    swiperdata(){
      return this.$store.state.swiperData;
    },
    newsList(){
      return this.$store.state.newsList;
    }
  },
  methods: {
    initbanner(){
      let _this = this;
      if(this.swiperdata.length > 0){
        this.swiper = new Swiper('.banner',{
          autoplay: {
            delay: 2500,
            disableOnInteraction: false,
          },
          loop: true,
          pagination: {
            el: '.swiper-pagination',
            bulletClass: 'swiperbullet',
            bulletActiveClass: 'swiperactivebullet',
          },
        })
      }else{
        setTimeout(() => {
          _this.initbanner();
        }, 10);
      }
    },
    reset(){
      this.searchContent = '';
    },
    search(){
      let searchContent = this.searchContent;
      this.$router.push({name: 'SearchSearch',params: { search: searchContent }});
    },
    topath(url){
      this.$router.push({path: url});
    },
    toUrl(url){
      window.open(url)
    }
  },
  mounted(){
    this.initbanner();
  },
}
</script>

<style lang="scss" scoped>

.banner{
  width: 100%;
  height: 4.5rem;
  .swiper-slide{
    img{
      width: 100%;
      height: 100%;
    }
  }
  
}
.container{
  width: 10.8rem;
  justify-content: space-between;
  align-content: flex-start;
  align-items: flex-start;
  .left{
    width: 3.5rem;
    .searchcontainer{
      padding: 0.3rem 0.2rem;
      overflow: hidden;
      .subtitle{
          height: 0.5rem;
          font-size: 0.16rem;
          color: #fff;
          background-color: $themecolor;
          justify-content: flex-start;
          font-weight: bold;
          padding-left: 0.15rem;
          border-radius: 0.1rem 0.1rem 0px 0px;
          box-shadow: 0px 0px 8px 0px rgba(225, 225, 225, 0.8);
          i{
              margin-right: 0.2rem;
          }
      }
      .content{
          align-content: flex-start;
          background-color: #fff;
          border-radius:  0px 0px 0.1rem 0.1rem;
          box-shadow: 0px 0px 8px 0px rgba(225, 225, 225, 0.8);
          padding: 0 0.2rem;
          .inputcontainer{
              margin-top: 0.5rem;
              .inputtitle{
                  font-size: 0.16rem;
                  font-weight: bold;
                  color: #2C2C2C;
              }
              .input{
                  width: 1.8rem;
                  height: 0.35rem;
                  border-radius: 0.06rem;
                  border: 1px solid #DDDDDD;
                  color: #2C2C2C;
              }
              .input::placeholder{
                  color: #E1E1E1;
              }
          }
          .buttoncontainer{
              margin-top: 0.3rem;
              margin-bottom: 0.5rem;
              .button{
                  width: 0.65rem;
                  height: 0.35rem;
                  border-radius: 0.06rem;
                  font-size: 0.14rem;
                  margin: 0.2rem;
                  cursor: pointer;
              }
              .search{
                  color: #fff;
                  background-color: $themecolor;
              }
              .reset{
                  border: 1px solid #DDDDDD;
                  color: #666;
              }
          }
          .tip{
              margin-top: 0.7rem;
              color: #999999;
              font-size: 0.14rem;
          }
      }
    }
  }
  .right{
    width: 7rem;
    align-content: flex-start;
    .msgcontainer{
      padding: 0.3rem 0.2rem;
      .subtitle{
          height: 0.5rem;
          font-size: 0.16rem;
          color: #fff;
          background-color: $themecolor;
          justify-content: flex-start;
          font-weight: bold;
          padding-left: 0.15rem;
          border-radius: 0.1rem 0.1rem 0px 0px;
          box-shadow: 0px 0px 8px 0px rgba(225, 225, 225, 0.8);
          i{
              margin-right: 0.2rem;
          }
      }
      .content{
          min-height: 3.5rem;
          align-content: flex-start;
          background-color: #fff;
          border-radius:  0px 0px 0.1rem 0.1rem;
          box-shadow: 0px 0px 8px 0px rgba(225, 225, 225, 0.8);
          padding: 0.3rem 0.2rem;
          .newsitem{
            height: 0.5rem;
            color: #4D4D4D;
            font-size: 0.14rem;
            justify-content: space-between;
            border-bottom: 1px solid #ddd;
            cursor: pointer;
            .title::before{
              content: ' ';
              display: inline-block;
              width: 0.06rem;
              height: 0.06rem;
              background-color: #4D4D4D;
              margin-right: 0.15rem;
            }
          }
          .newsitem:hover{
            color: $themecolor;
          }
          
      }
    }
  }
}
</style>
  