<template>
  <div>
    <div class="flexbox title">专家力量</div>
    <div class="flexbox content">
      <div class="flexbox expert" v-for="item of expert" :key="item">
        <img :src="item.path">
        <div class="name">{{ item.title }}</div>
        <div class="desc">{{ item.content }}</div>
      </div>
    </div>
  </div>
  
</template>

<script>
export default {
  data(){
    return {
    
    }
  },
  computed:{
    expert(){
      return this.$store.state.experts;
    }
  },
  methods:{
    getData(){
      this.$gl.post('index.php/api/index/expert', {}, res => {
        res.forEach(item => {
          item.path = this.$gl.imgdomain + item.path;
        })
        this.$store.commit('setExpert',res);
      })
    }
  },
  created(){
    this.getData();
  }
}
</script>

<style lang="scss" scoped>
  .title{
    height: 0.35rem;
    font-size: 0.16rem;
    color: $themecolor;
    border-bottom: 1px solid #666;
    justify-content: flex-start;
    font-weight: bold;
  }
  .content{
    justify-content: flex-start;
    align-items: flex-start;
    align-content: flex-start;
    padding: 0.3rem 0;
   .expert{
      width: 2.45rem;
      font-size: 0.14rem;
      margin-right: 0.15rem;
      padding-bottom: 0.1rem;
      box-shadow: 0px 0px 4px 0px rgba(196, 196, 196, 0.5);
      background-color: #fff;
      border-radius: 0.06rem;
      overflow: hidden;
      justify-content: flex-start;
      img{
        width: 2.45rem;
        height: 2.45rem;
      }
      .name{
        width: 100%;
        padding: 0.05rem 0.15rem;
        color: $themecolor;
      }
      .desc{
        width: 100%;
        padding: 0.05rem 0.15rem;
        color: #202020;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
</style>