import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import global from './global'

var app = createApp(App);
app.config.globalProperties.$gl = global;

app.use(store).use(router).mount('#app')
