import { createRouter, createWebHashHistory } from 'vue-router'
import HomeIndex from '../views/home/index.vue'

import AboutIndex from '../views/about/index.vue'
import AboutExpert from '../views/about/expert.vue'
import AboutIntroduce from '../views/about/introduce.vue'
import AboutQuelifation from '../views/about/quelifacation.vue'
import AboutRecruit from '../views/about/recruit.vue'

import SearchIndex from '../views/search/index.vue'
import SearchSearch from '../views/search/search.vue'
import SearchTemplate from '../views/search/template.vue'

import ServiceIndex from '../views/service/index.vue'
import ServiceDevice from '../views/service/device.vue'
import ServiceProcedure from '../views/service/procedure.vue'
import ServiceProject from '../views/service/project.vue'
import ServiceStandard from '../views/service/standard.vue'



const routes = [
  {
    path: '/',
    name: 'HomeIndex',
    component: HomeIndex
  },
  {
    path: '/about',
    name: 'AboutIndex',
    component: AboutIndex,
    redirect: '/about/introduce',
    children: [
      {
        path: 'expert',
        name: 'AboutExpert',
        component: AboutExpert,
      },
      {
        path: 'introduce',
        name: 'AboutIntroduce',
        component: AboutIntroduce,
      },
      {
        path: 'quelifacation',
        name: 'AboutQuelifation',
        component: AboutQuelifation,
      },
      {
        path: 'recruit',
        name: 'AboutRecruit',
        component: AboutRecruit,
      },
    ],
  },
  {
    path: '/search',
    name: 'SearchIndex',
    component: SearchIndex,
    redirect: '/search/search',
    children: [
      {
        path: 'search',
        name: 'SearchSearch',
        component: SearchSearch,
      },
      {
        path: 'template',
        name: 'SearchTemplate',
        component: SearchTemplate,
      },
    ],
  },
  {
    path: '/service',
    name: 'ServiceIndex',
    component: ServiceIndex,
    redirect: '/service/project',
    children: [
      {
        path: 'project',
        name: 'ServiceProject',
        component: ServiceProject,
      },
      {
        path: 'procedure',
        name: 'ServiceProcedure',
        component: ServiceProcedure,
      },
      {
        path: 'device',
        name: 'ServiceDevice',
        component: ServiceDevice,
      },
      {
        path: 'standard',
        name: 'ServiceStandard',
        component: ServiceStandard,
      },
    ],
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
