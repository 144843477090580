<template>
  <div class="flexbox content">
    <Header />
    <router-view class="routerview"/>
    <Footer />
  </div>
</template>

<script>
import Header from './components/Header'
import Footer from './components/Footer'
export default {
  components: {
    Header,
    Footer
  },
  methods: {
    getData(){
      this.$gl.post('index.php/api/Index/index',{},res => {
        if(res.status == 200){
          res.data.imgs.forEach(item => {
            item.img = this.$gl.imgdomain + item.img;
          })
          this.$store.commit('setData',res.data);
        }
      })
      this.$gl.post('index.php/api/Index/newsList',{},res => {
        if(res.status == 200){
           res.data.forEach(item => {
            item.time = this.$gl.getDateByTimeStamp(item.updatetime);
          })
          this.$store.commit('setNewsList',res.data);
        }
      })
    }
  },
  created(){
    this.getData();
  },
}
</script>

<style lang="scss" scoped>
.content{
  position: relative;
  min-height: 100vh;
  align-items: flex-start;
  align-content: flex-start;
}
.routerview{
  width: 100%;
}
</style>

