<template>
  <div class="flexbox">
      <div class="flexbox titlecontainer fullbg">
        <div class="flexbox title">
          服务介绍
        </div>
      </div>
      <div class="flexbox content">
        <div class="flexbox navlist">
          <div class="flexbox title">
            <i class="iconfont icon-guanyuwomen"></i>
            服务介绍
          </div>
          <router-link class="flexbox item" to="/service/project">
            <div>承接业务</div> 
            <div class="activeblock"></div>
          </router-link>
          <router-link class="flexbox item" to="/service/device">
            <div>仪器设备</div>
            <div class="activeblock"></div>
          </router-link>
          <router-link class="flexbox item" to="/service/procedure">
            <div>检测流程</div>
            <div class="activeblock"></div>
          </router-link>
          <router-link class="flexbox item" to="/service/standard">
            <div>执行标准</div>
            <div class="activeblock"></div>
          </router-link>
        </div>
        <div class="flexbox linecontainer">
          <div class="line"></div>
        </div>
        <router-view class="flexbox viewcontainer"></router-view>
      </div>
      
  </div>
</template>

<script>
export default {

}
</script>

<style lang="scss" scoped>
  .titlecontainer{
    width: 100%;
    height: 3rem;
    background-image: url(../../assets/servicebg.png);
    .title{
      width: 10rem;
      min-width: 1000px;
      font-size: 0.48rem;
      font-weight: bold;
      text-decoration: underline;
      margin-left: 0.5rem;
      color: #fff;
      letter-spacing: 0.1rem;
      justify-content: flex-start;
    }
  }
  
  .content{
    width: 10rem;
    min-width: 1000px;
    flex-wrap: nowrap;
    margin-top: 0.5rem;
    align-items: flex-start;
    align-content: flex-start;
    .navlist{
      width: 1.75rem;
      flex-shrink: 0;
      .title{
        height: 0.5rem;
        font-size: 0.16rem;
        color: $themecolor;
        font-weight: bold;
        i{
          margin-right: 0.2rem;
          color: $themecolor;
        }
      }
      .item{
        width: 100%;
        height: 0.65rem;
        font-size: 0.16rem;
        color: $fontcolor;
        justify-content: space-between;
        padding-left: 0.5rem;
        font-weight: bold;
        .activeblock{
          width: 0.03rem;
          height: 0.15rem;
          background-color: $themecolor;
          display: none;
        }
      }
      .router-link-active{  
        color: $themecolor !important;
        .activeblock{
          display: inline-block !important;
        }
      }
    }
    .linecontainer{
      width: 1px;
      height: 3.2rem;
      .line{
        width: 1px;
        height: 2.8rem;
        border-right: 1px solid #666;
      }
    }
    
    .viewcontainer{
      min-height: 2.8rem;
      padding: 0 0.25rem;
      align-items: flex-start;
      align-content: flex-start;
    }
  }

</style>