<template>
  <div>
    <div class="flexbox title">承接业务</div>
    <div class="content" v-html="html"></div>
  </div>
</template>

<script>
export default {
  data(){
    return {
    }
  },
  computed: {
    html(){
      return this.$store.state.html.project;
    }
  },
  methods: {
    getData(){
      this.$gl.post('index.php/api/index/article',{
        url: '/service/project',
      },res => {
        this.$store.commit('setHTML',{
          key: 'project',
          html: this.$gl.htmlDecodeByRegExp(res[0].content),
        });
      });
    }
  },
  created(){
    this.getData();
  }
}
</script>

<style lang="scss" scoped>
  .title{
    height: 0.35rem;
    font-size: 0.16rem;
    color: $themecolor;
    border-bottom: 1px solid #666;
    justify-content: flex-start;
    font-weight: bold;
  }
  .content{
    width: 100%;
    height: auto;
    font-size: 14px;
    p{
      font-size: 0.16rem;
      color: $fontcolor;
    }
  }
</style>