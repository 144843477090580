<template>
  <div class="footertool"></div>
  <div class="flexbox container">
    <div class="flexbox line">
      <span>版权归四川大道本真检测技术有限公司所有</span>
      <span>邮编：610041</span>
      <span>联系电话：4006028102</span>
      <span>地址：中国(四川)自由贸易试验区成都高新区纪城路198号附2171号1层</span>
    </div>
    <div class="flexbox line">
      <span>版权说明</span>
      <span>隐私声明</span>
      <span>备案/许可证号：蜀ICP备2020029396号</span>
      <!-- <span>公安备案：00000000000</span> -->
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    name(){
      return this.$store.state.companyName;
    },
  }
}
</script>

<style lang="scss" scoped>
  .container{
    position: absolute;
    left: 0;
    bottom: 0;
    height: 1.5rem;
    background-color: #27374D;
    .line{
      height: 0.25rem;
      color: #fff;
      font-size: 0.12rem;
      span{
        padding: 0.2rem;
      }
    }
  }
  .footertool{
    height: 1.5rem;
    width: 100%;
  }
</style>