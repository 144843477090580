<template>
    <div class="flexbox container">
        <div class="flexbox logo">
            <span class="logoimg" :style="logo"></span>
            {{ name }}
        </div>
        <div class="flexbox navigator">
            <router-link to="/">
                <div class="flexbox item">首页</div>
            </router-link>
            <router-link to="/search">
                <div class="flexbox item">
                    证书查询 
                    <div class="flexbox subnavi">
                        
                    </div>
                </div>
            </router-link>
            <router-link to="/about">
                <div class="flexbox item">
                    关于我们
                    <div class="flexbox subnavi">
                        
                    </div>
                </div>
            </router-link>
            <router-link to="/service">
                <div class="flexbox item">
                    服务介绍
                    <div class="flexbox subnavi">
                        
                    </div>
                </div>
            </router-link>
        </div>
    </div>
</template>

<script>
export default {
    computed: {
        name(){
            return this.$store.state.companyName;
        },
        logo(){
            return{ backgroundImage: `url(${this.$gl.imgdomain + this.$store.state.logoUrl})`};
        }
    }
}
</script>

<style lang="scss" scoped>
    .container{
        width: 10rem;
        height: 0.9rem;
        min-width: 1000px;
        flex-wrap: nowrap;
    }
    .logo{
        width: 100%;
        height: 50px;
        font-size: 0.28rem;
        color: $themecolor;
        justify-content: flex-start;
    }
    .logoimg{
        width: 0.35rem;
        height: 0.35rem;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        margin-right: 0.1rem;
    }
    .navigator{
        .item{
            width: 1rem;
            font-size: 0.18rem;
            color: $themecolor;
        }
    }
    .router-link-active{  
        font-weight: bold;
    }

</style>