import { createStore } from 'vuex'

export default createStore({
  state: {
    companyName: '',
    swiperData: [],
    logoUrl: '',
    html: {
      project: '',
      procedure: '',
      device: '',
      introduce: '',
      quelifacation: '',
      recruit: '',
    },
    newsList: [],
    experts: [],
    standard: []
  },  
  mutations: {
    setData(state,data){
      state.companyName = data.corporate;
      state.swiperData = data.imgs;
      state.logoUrl = data.logo;
    },
    setHTML(state,data){
      state.html[data.key] = data.html;
    },
    setNewsList(state,data){
      state.newsList = [...data];
    },
    setExpert(state,data){
      state.experts = data;
    },
    setStandard(state,data){
      state.standard = data;
    },
  },
  actions: {
  },
  modules: {
  }
})
