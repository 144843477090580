const axios = require('axios');
import config from './config.js'
export default {
    imgdomain: config.domain,
    getQueryString(name){
        var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)", "i");
        var r = window.location.search.substr(1).match(reg);
        if (r != null) return unescape(r[2]);
        return null;
    },
    post(url,params,success){
        const formData = new FormData();
        Object.keys(params).forEach((key) => {
            formData.append(key, params[key]);
        });
        axios.post(url,formData).then(
            res => {
                success(res.data);
            }
        ).catch(
            error => {
                console.log(error);
            }
        );
    },
    get(url,data,success){
        let paramurl = url + '?';
        let paramarr = [];
        for(let key in data){
            paramarr.push(`${key}=${data[key]}`)
        }
        paramurl += paramarr.join('&');
        axios({
            method: 'get',
            url: paramurl,
            responseType: 'stream'
        })
        .then(function (res) {
            success(res.data);
        });
    },
    htmlEncodeByRegExp(str) {  
        var s = "";
        if(str.length == 0) return "";
        s = str.replace(/&/g,"&amp;");
        s = s.replace(/</g,"&lt;");
        s = s.replace(/>/g,"&gt;");
        s = s.replace(/\s/g,"&nbsp;");
        s = s.replace(/\'/g,"&#39;");
        s = s.replace(/\"/g,"&quot;");
        return s;  
    },
    htmlDecodeByRegExp(str) {  
            var s = "";
            if(str.length == 0) return "";
            s = str.replace(/&amp;/g,"&");
            s = s.replace(/&lt;/g,"<");
            s = s.replace(/&gt;/g,">");
            s = s.replace(/&nbsp;/g," ");
            s = s.replace(/&#39;/g,"\'");
            s = s.replace(/&quot;/g,"\"");
            return s;  
    },
    getDateByTimeStamp(stamp){
        let datetime = [];
        let date = new Date(stamp * 1000);
        datetime.push(date.getFullYear());
        datetime.push(date.getMonth() + 1);
        datetime.push(date.getDate());
        return datetime.join('-');
    },
    /**
     * @function 获取链接传值
     * @param {String} name 键名
     */
    getQueryString(name) {
        var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)");
        var r = window.location.search.substr(1).match(reg);
        if (r != null) return decodeURI(r[2]);
        return null;
    },

    /**
     * @function 将键值对转成链接参数
     * @param {Object} data 
     */
    urlEncode(data) {
        var _result = [];
        for (var key in data) {
            var value = null;
            if (data.hasOwnProperty(key)) value = data[key];
            if (value.constructor === Array) {
                value.forEach(function (_value) {
                    _result.push(key + "=" + _value);
                });
            } else {
                _result.push(key + '=' + value);
            }
        }
        return _result.join('&');
    },
}