<template>
<div>
    <div class="flexbox title">证书样板</div>
    <div class="flexbox container">
        <div class="flexbox subtitle">
            <i class="iconfont icon-search"></i>
            证书样板
        </div>
        <div class="flexbox content">
            <div class="flexbox resultcontainer">
                <div class="flexbox left">
                    <div class="flexbox item">
                        <div class="item_title">鉴定结果</div>
                        <div></div>
                    </div>
                    <div class="flexbox item">
                        <div class="item_title">总重量</div>
                        <div></div>
                    </div>
                    <div class="flexbox item">
                        <div class="item_title">形状</div>
                        <div></div>
                    </div>
                    <div class="flexbox item">
                        <div class="item_title">主石名称</div>
                        <div></div>
                    </div>
                    <div class="flexbox item">
                        <div class="item_title">颜色</div>
                        <div></div>
                    </div>
                    <div class="flexbox item">
                        <div class="item_title">折射率</div>
                        <div></div>
                    </div>
                    <div class="flexbox item">
                        <div class="item_title">密度</div>
                        <div></div>
                    </div>
                    <div class="flexbox item">
                        <div class="item_title">放大检查</div>
                        <div></div>
                    </div>
                    <div class="flexbox item">
                        <div class="item_title">备注</div>
                        <div></div>
                    </div>
                    <div class="flexbox item">
                        <div class="item_title">鉴定师</div>
                        <div></div>
                    </div>
                    <div class="flexbox item">
                        <div class="item_title">审核人</div>
                        <div></div>
                    </div>
                    <div class="flexbox item">
                        <div class="item_title">检测编号</div>
                        <div></div>
                    </div>
                </div>
                <div class="flexbox right">
                    <div class="flexbox img">珠宝图片</div>
                    <div style="width:100%;height: 1px"></div>
                    <div class="flexbox qrcode">二维码</div>
                </div>
            </div>
        </div>
    </div>
</div>
  
  
</template>

<script>
export default {

}
</script>

<style lang="scss" scoped>
.title{
    height: 0.35rem;
    font-size: 0.16rem;
    color: $themecolor;
    border-bottom: 1px solid #666;
    justify-content: flex-start;
    font-weight: bold;
  }
.container{
    padding: 0.3rem 0.2rem;
    overflow: hidden;
    .subtitle{
        height: 0.5rem;
        font-size: 0.16rem;
        color: #fff;
        background-color: $themecolor;
        justify-content: flex-start;
        font-weight: bold;
        padding-left: 0.15rem;
        border-radius: 0.1rem 0.1rem 0px 0px;
        box-shadow: 0px 0px 8px 0px rgba(225, 225, 225, 0.8);
        i{
            margin-right: 0.2rem;
        }
    }
    .content{
        .resultcontainer{
            align-content: flex-start;
            background-color: #fff;
            border-radius:  0px 0px 0.1rem 0.1rem;
            box-shadow: 0px 0px 8px 0px rgba(225, 225, 225, 0.8);
            justify-content: space-between;
            align-items: flex-start;
            align-content: flex-start;
            padding: 0.3rem;
            .left{
                width: 40%;
                .item{
                    min-height: 0.6rem;
                    padding: 0.22rem 0;
                    color: #202020;
                    font-size: 0.16rem;
                    font-weight: bold;
                    justify-content: space-between;
                    border-bottom: 1px solid #E1E1E1;
                    flex-wrap: nowrap;
                    .item_title{
                        flex-shrink: 0;
                        margin-right: 0.2rem;
                        align-self: flex-start;
                    }
                }
            }
            .right{
                width: 50%;
                justify-content: flex-start;
                align-items: flex-start;
                align-content: flex-start;
                .img{
                    width: 3.5rem;
                    height: 3.5rem;
                    background-size: cover;
                    background-position: center;
                    background-repeat: no-repeat;
                    background-color: #E1E1E1;
                    font-size: 0.18rem;
                    color: #666;
                    font-weight: bold;
                }
                .qrcode{
                    width: 1.7rem;
                    height: 1.7rem;
                    background-size: cover;
                    background-position: center;
                    background-repeat: no-repeat;
                    margin-top: 1rem;
                    background-color: #E1E1E1;
                    font-size: 0.18rem;
                    color: #666;
                    font-weight: bold;
                }
            }

        }
    }
}
  
</style>