<template>
  <div class="container">
      <img ref="image" :src="url" :alt="alt">
  </div>
</template>

<script>
export default {
    props: ['url','alt'],
    methods:{
        initviewer(){
            new Viewer(this.$refs.image, {
                inline: false,
                button: true,
                navbar: false,
                toolbar: false,
            });
        }
    },
    mounted(){
        this.initviewer();
    }
}
</script>

<style lang="scss" scoped>
.container{
    width: 100%;
    img{
        width: 90%;
        height: auto;
    }
}
</style>