<template>
<div>
    <div class="flexbox title">证书查询</div>
    <div class="flexbox container" v-show="!showQueryTemp">
        <div class="flexbox subtitle">
            <i class="iconfont icon-search"></i>
            证书查询
        </div>
        <div class="flexbox content">
            <div class="flexbox inputcontainer">
                <div class="inputtitle">证书编号：</div>
                <input class="input" type="text" placeholder="请输入证书编号" v-model="searchContent" @keydown.enter="search">
            </div>
            <div class="flexbox buttoncontainer">
                <div class="flexbox button search" @click="search">查询</div>
                <div class="flexbox button reset" @click="reset">重置</div>
            </div>
            <div class="flexbox tip" style="margin-bottom: 0.03rem">温馨提示：若您未查询到结果，可能数据正在上传，请过两天再次查询。给您带来不便，敬请见谅！</div>
            <div class="flexbox tip" style="margin-top: 0.03rem; margin-bottom: 0.03rem">证书查询时需要根据证书类型及样品编号进行查询:</div>
              <div class="flexbox tip" style="margin-top: 0.03rem; margin-bottom: 0.03rem">如查询小证信息则需输入样品编号“+2”查询证书；</div>
              <div class="flexbox tip" style="margin-top: 0.03rem; margin-bottom: 0.03rem">如查询大证信息则需输入样品编号“+1”查询证书；</div>
              <div class="flexbox tip" style="margin-top: 0.03rem;">例：样品编号为YP2021000064且为小证，则输入642可进行查询。</div>
        </div>
    </div>
    <div class="flexbox container" v-show="showQueryTemp">
        <div class="flexbox subtitle">
            <i class="iconfont icon-search"></i>
            鉴定结果
        </div>
        <div v-show="!showError" class="flexbox resultcontainer">
            <div class="flexbox left">
                <div class="flexbox item">
                    <div class="item_title">鉴定结果</div>
                    <div>{{ result.result }}</div>
                </div>
                <div class="flexbox item">
                    <div class="item_title">总重量</div>
                    <div>{{ result.weight }}</div>
                </div>
                <div class="flexbox item">
                    <div class="item_title">形状</div>
                    <div>{{ result.shape }}</div>
                </div>
                <div class="flexbox item">
                    <div class="item_title">主石名称</div>
                    <div>{{ result.name }}</div>
                </div>
                <div class="flexbox item">
                    <div class="item_title">颜色</div>
                    <div>{{ result.color }}</div>
                </div>
                <div class="flexbox item">
                    <div class="item_title">折射率</div>
                    <div>{{ result.refractive }}</div>
                </div>
                <div class="flexbox item">
                    <div class="item_title">密度</div>
                    <div>{{ result.density }}</div>
                </div>
                <div class="flexbox item">
                    <div class="item_title">放大检查</div>
                    <div>{{ result.magnifyingexamination }}</div>
                </div>
                <div class="flexbox item">
                    <div class="item_title">备注</div>
                    <div>{{ result.remarks }}</div>
                </div>
                <div class="flexbox item">
                    <div class="item_title">鉴定师</div>
                    <div>{{ result.inspectortwo }}</div>
                </div>
                <div class="flexbox item">
                    <div class="item_title">审核人</div>
                    <div>{{ result.auditor }}</div>
                </div>
                <div class="flexbox item">
                    <div class="item_title">检测编号</div>
                    <div>{{ result.detectionnumber }}</div>
                </div>
            </div>
            <div class="flexbox right">
                <div class="img" :style="{backgroundImage: `url(${result.detapic})`}"></div>
                <div style="width:100%;height: 1px"></div>
                <div class="qrcode" :style="{backgroundImage: `url('${result.ewmimg}')`}"></div>
            </div>
        </div>
        <div v-show="showError" class="flexbox noresultcontainer">
            <div class="flexbox imgcontainer"></div>
            <div class="flexbox noresulttext">
                没有查询到证书信息，请核对编号输入是否有误！
            </div>
        </div>
        <div class="flexbox backcontainer">
            <div class="flexbox back" @click="back">返回</div>
        </div>
    </div>
</div>
</template>

<script>
export default {
    data(){
        return {
            searchContent: '',
            showQueryTemp: false,
            showError: false,
            result: {},
        }
    },
    methods: {
        checkRoutParams(){
            this.searchContent = this.$route.params.search ? this.$route.params.search : '';
            if(this.$route.params.search){
                this.search();
            }
        },
        search(){
            let _this = this;
            if(this.searchContent == '') return false;
            this.showQueryTemp = true;
            this.$gl.post('index.php/api/index/certificateShow',{
                certid: this.searchContent,
            }, res => {
                if(res.status == 200){
                    res.data.detapic = _this.$gl.imgdomain + res.data.detapic;
                    res.data.ewmimg = _this.$gl.imgdomain + res.data.ewmimg;
                    _this.result = res.data;
                }else{
                    _this.showError = true;
                }
            })
        },
        reset(){
            this.searchContent = '';
        },
        back(){
            // this.searchContent = '';
            this.showQueryTemp = false;
            this.showError = false;
        }
    },
    mounted(){
        this.checkRoutParams();
    }

}
</script>

<style lang="scss" scoped>
.title{
    height: 0.35rem;
    font-size: 0.16rem;
    color: $themecolor;
    border-bottom: 1px solid #666;
    justify-content: flex-start;
    font-weight: bold;
  }
.container{
    padding: 0.3rem 0.2rem;
    overflow: hidden;
    .subtitle{
        height: 0.5rem;
        font-size: 0.16rem;
        color: #fff;
        background-color: $themecolor;
        justify-content: flex-start;
        font-weight: bold;
        padding-left: 0.15rem;
        border-radius: 0.1rem 0.1rem 0px 0px;
        box-shadow: 0px 0px 8px 0px rgba(225, 225, 225, 0.8);
        i{
            margin-right: 0.2rem;
        }
    }
    .resultcontainer{
        align-content: flex-start;
        background-color: #fff;
        border-radius:  0px 0px 0.1rem 0.1rem;
        box-shadow: 0px 0px 8px 0px rgba(225, 225, 225, 0.8);
        justify-content: space-between;
        align-items: flex-start;
        align-content: flex-start;
        padding: 0.3rem;
        .left{
            width: 40%;
            .item{
                min-height: 0.6rem;
                padding: 0.22rem 0;
                color: #202020;
                font-size: 0.16rem;
                font-weight: bold;
                justify-content: space-between;
                border-bottom: 1px solid #E1E1E1;
                flex-wrap: nowrap;
                .item_title{
                    flex-shrink: 0;
                    margin-right: 0.2rem;
                    align-self: flex-start;
                }
            }
        }
        .right{
            width: 50%;
            justify-content: flex-start;
            align-items: flex-start;
            align-content: flex-start;
            .img{
                width: 3.5rem;
                height: 3.5rem;
                background-size: cover;
                background-position: center;
                background-repeat: no-repeat;
            }
            .qrcode{
                width: 1.7rem;
                height: 1.7rem;
                background-size: cover;
                background-position: center;
                background-repeat: no-repeat;
                margin-top: 1rem;
            }
        }

    }
    .noresultcontainer{
        background-color: #fff;
        border-radius:  0px 0px 0.1rem 0.1rem;
        box-shadow: 0px 0px 8px 0px rgba(225, 225, 225, 0.8);
        justify-content: space-between;
        align-items: flex-start;
        align-content: flex-start;
        padding: 0.3rem;
        .imgcontainer{
            height: 1.5rem;
            background-image: url(../../assets/noresult.png);
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center;
            margin-top: 0.5rem;
            margin-bottom: 0.5rem;
        }
        .noresulttext{
            color: #999;
            font-size: 0.14rem;
            margin-bottom: 0.5rem;
        }
    }
    .backcontainer{
        justify-content: flex-end;
        margin-top: 0.1rem;
        .back{
            width: 1rem;
            height: 0.35rem;
            color: #fff;
            background-color: $themecolor;
            font-size: 0.14rem;
            border-radius: 0.06rem;
            cursor: pointer;
        }
    }
    .content{
        align-content: flex-start;
        background-color: #fff;
        border-radius:  0px 0px 0.1rem 0.1rem;
        box-shadow: 0px 0px 8px 0px rgba(225, 225, 225, 0.8);
        .inputcontainer{
            margin-top: 0.6rem;
            .inputtitle{
                font-size: 0.16rem;
                font-weight: bold;
                color: #2C2C2C;
            }
            .input{
                width: 1.8rem;
                height: 0.35rem;
                border-radius: 0.06rem;
                border: 1px solid #DDDDDD;
                color: #2C2C2C;
            }
            .input::placeholder{
                color: #E1E1E1;
            }
        }
        .buttoncontainer{
            margin-top: 0.5rem;
            .button{
                width: 0.65rem;
                height: 0.35rem;
                border-radius: 0.06rem;
                font-size: 0.14rem;
                margin: 0.2rem;
                cursor: pointer;
            }
            .search{
                color: #fff;
                background-color: $themecolor;
            }
            .reset{
                border: 1px solid #DDDDDD;
                color: #666;
            }
        }
        .tip{
            margin-top: 0.3rem;
            margin-bottom: 0.3rem;
            color: #999999;
            font-size: 0.14rem;
        }
    }
}
  
</style>