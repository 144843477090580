<template>
  <div>
    <div class="flexbox title">执行标准</div>
    <div class="flexbox content">
      <div class="flexbox item" v-for="item of standard" :key="item">
        <div class="imgtitle">{{ item.title }}</div>
        <Viewer :url="item.path" :alt="item.title" />
      </div>
    </div>
  </div>
  
</template>

<script>
import Viewer from '../../components/viewer'
export default {
  data(){
    return{
      
    }
  },
  components: {
    Viewer
  },
  computed: {
    standard(){
      return this.$store.state.standard;
    },
    images(){
      return this.standard.map(item => {
        return item.path;
      })
    }
  },
  methods:{
    getData(){
      this.$gl.post('index.php/api/index/standard', {}, res => {
        res.forEach(item => {
          item.path = this.$gl.imgdomain + item.path;
        })
        this.$store.commit('setStandard',res);
      })
    }
  },
  created(){
    this.getData();
  },
}
</script>

<style lang="scss" scoped>
  .title{
    height: 0.35rem;
    font-size: 0.16rem;
    color: $themecolor;
    border-bottom: 1px solid #666;
    justify-content: flex-start;
    font-weight: bold;
  }
  .content{
    justify-content: flex-start;
    align-items: flex-start;
    align-content: flex-start;
    padding: 0.3rem;
    .item{
      width: 50%;
      height: auto;
      justify-content: flex-start;
      align-content: flex-start;
      align-items: flex-start;
      margin-bottom: 0.5rem;
      .imgtitle{
        width: 100%;
        font-size: 0.16rem;
        color: #666666;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }
    p{
      font-size: 0.16rem;
      color: $fontcolor;
    }
  }
</style>